if (document.getElementsByClassName('its--online-fix').length == 0) {
    window.onscroll = function() {
        scrollFunction();
    };
}

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById('myBtn').style.display = 'block';
    } else {
        document.getElementById('myBtn').style.display = 'none';
    }
}

function topFunction() {
    jQuery('html, body').stop().animate({
        scrollTop: 0
    }, 1500, 'swing');

    event.preventDefault();
}


var message;
var avisoRojo = document.getElementById('avisoReserva');

if (document.documentElement.lang == 'FR') {
    message = 'Introduisez vos dates et cliquez sur "calculer" pour voir disponibilité et prix.';
    
    if (message == avisoRojo.innerHTML) {
        avisoRojo.innerHTML = 'Introduisez vos dates et cliquez sur "afficher le prix" pour voir disponibilité et prix.';
    }
}

if (document.documentElement.lang == 'EN') {
    message = 'Please enter your dates in the form above, then click on "search" for availability and price.';

    if (message == avisoRojo.innerHTML) {
        avisoRojo.innerHTML = 'Please enter your dates in the form above, then click on "show the price" for availability and price.';
    }
}